import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, Checkbox, Card  } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import type { CheckboxProps  } from 'antd';

const dateFormat = 'YYYY-MM-DD';

export default class AdditionalCost extends React.Component {
    props = {planId: 0};
    permissions = sessionStorage.getItem("permissions") || '';
    constructor(props:any) {
        super(props);
      }
    
    columns = [
    {
        title: '操作',
        key: 'operation',
        width: 100,
        render: (_: any, record: any)=> {
            return (
                <div> {
                    this.permissions.includes('财务总监')?
                    <>
                    <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                    <Popconfirm
                        title="删除该记录" 
                        description="是否确定删除?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => this.onDelete(record)}
                    >
                        <DeleteOutlined title='删除' className="actionIcon"/>
                    </Popconfirm>
                    </>
                    :''
                    }                                 
                </div>
            )
        }
    },
    {
        title: '供应商',
        dataIndex: 'vendorName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.vendorName > b.vendorName? 1 : -1,
    },
    {
        title: '费用类型',
        dataIndex: 'expenseType', 
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.expenseType > b.expenseType? 1 : -1,
    },
    {
        title: '费用项目',
        dataIndex: 'subExpense',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.subExpense > b.subExpense? 1 : -1,
    },
    {
        title: '备注',
        dataIndex: 'comments',
    },  
   
    {
        title: '应付款',
        dataIndex: 'cost',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.amount > b.amount? 1 : -1,
    },  
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,       
        isLoaded: false,
        isEdit: false,
        unitPriceVisible: false,
    };
    allPermissions: any[] = [];
    
    editRecord = this.createAdditionalCost();
    originalEditRecords: any = {};
    canBeSaved = false;
    containerCount = 0;
    totalCost = 0;    
    vendorList: any[] = [];

    expenseTypeList: any[] = [{label:'基本支出', value: 'baseCost'},{label:'异常支出', value: 'AdditionalCost'}]; 
    baseCostList: any[] = []; 
    AdditionalCostList: any[] = []; 
    subExpenseList: any[] = []; 

    createAdditionalCost() {
        return { 
            id : 0,
            planId : this.props.planId,
            expenseType : '',
            subExpense : '',            
            cost : '',
            comments : '',
            vendorId: '',
        };
    }
    
    getAllBaseExpenses() {        
        axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'baseCost'}}).then(
            response => {
                this.baseCostList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.baseCostList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});   
                    console.log('this.baseCostList',this.baseCostList)            
                }
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        )       
       
    }

    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorId});               
                }
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )
    }

    getAllAdditionalCosts() {
        axiosInstance.get('/logistics/getCommonPropertiesByKey',{params: {key: 'additionalCost'}}).then(
            response => {
                this.AdditionalCostList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.AdditionalCostList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});                             
                }
            },
            error => {
                displayErrorMessage(error, '获取数据失败');
            }
        )
       
    }
    
    
    copyAdditionalCost(AdditionalCost: any) {
        return { 
            id : AdditionalCost.id,
            planId : this.props.planId,
            expenseType : AdditionalCost.expenseType,
            subExpense : AdditionalCost.subExpense,            
            cost : AdditionalCost.cost,            
            comments : AdditionalCost.comments,
            vendorId : AdditionalCost.vendorId,
        };
    }

    getContainerCount() {
        axiosInstance.get('/logistics/getShipmentPlanById', {params: {planId: this.props.planId}}).then(
            response => {                
                this.containerCount = response.data.containerCount;
                console.log('this.containerCount',this.containerCount);
            },
            error => {
                displayErrorMessage(error, '获取装箱计划图片失败');
            }
        )

    }

    onDelete = (record: any) => {
        const AdditionalCost = {id: record.id, planId: this.props.planId};
        axiosInstance.post('/logistics/deleteAdditionalCostById', AdditionalCost).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }

    onExport = () => {
        this.setState({isLoaded: false});
        axiosInstance.get('/logistics/exportCost', {params: {planId: this.props.planId},responseType: 'blob'}).then(               
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '支出明细.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }
    onAdd = () => {
        this.editRecord = this.createAdditionalCost();
        this.originalEditRecords = this.createAdditionalCost();
        this.canBeSaved = false;
        this.setState({isEdit: true, payByCustomer: ''});
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyAdditionalCost(record);
        this.editRecord = this.copyAdditionalCost(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const AdditionalCost= this.copyAdditionalCost(this.editRecord);
        if (AdditionalCost.id === 0) {            
            axiosInstance.post('/logistics/insertAdditionalCost', AdditionalCost).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateAdditionalCost', AdditionalCost).then(
                response => {
                    this.init();
                    message.success('操作成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    onChangeCheckbox = (event: any) => {
        console.log(`checked = ${event.target.checked}`);
    };
      
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        if(field === 'expenseType'){                        
            if(event === 'AdditionalCost'){
                this.subExpenseList = this.AdditionalCostList                                         
                this.setState({unitPriceVisible: false});
            }else{
                this.subExpenseList = this.baseCostList                                                         
                this.setState({unitPriceVisible: true});                
            }                 
        }
        this.checkCanBeSaved();
    }

    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }   
    changeUnitPriceForCost = (field: string, event: any) => {
        this.editRecord.cost = event.target.value * this.containerCount +'';        
        this.checkCanBeSaved(); 
    }      
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.expenseType !== null && this.editRecord.expenseType !== '' &&
        this.editRecord.subExpense !== null && this.editRecord.subExpense !== '' //&&
            ) {
            if (this.editRecord.expenseType !== this.originalEditRecords.expenseType ||
                this.editRecord.subExpense !== this.originalEditRecords.subExpense ||               
                this.editRecord.comments !== this.originalEditRecords.payByCustomer )
                 {
                    this.canBeSaved = true;
            }
        }  
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';        
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false, payByCustomer: ''});
        this.getContainerCount();
        this.getAdditionalCostByPlanId();
        this.getAllBaseExpenses();
        this.getAllAdditionalCosts();
        this.getAllVendors();
    }
    getAdditionalCostByPlanId() {
        this.totalCost = 0;
        axiosInstance.get('/logistics/getAdditionalCostByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                    this.originalData[i].expenseType === 'baseCost'? this.originalData[i].expenseType ='基本支出': this.originalData[i].expenseType ='异常支出';
                    this.originalData[i].containerCount = this.containerCount;
                    this.originalData[i].subTotal = this.originalData[i].cost;
                    this.totalCost = this.totalCost + this.originalData[i].cost;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});                
            },
            error => {
                displayErrorMessage(error, '获取信息失败');
            }
        )

    }
    onChange111 = (field: string, event: any) => {                
        (this.editRecord as any)[field] = event?.join(',');
        this.checkCanBeSaved();
    };
    
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>
                {this.state.isEdit? 
                    <div className="content">   

                        <div className="row">
                            <div className="box">
                                <label className="box-label">费用类型:</label>                                
                                <Select className="box-input"  options={this.expenseTypeList} onChange={this.changeSelect.bind(this, 'expenseType')} defaultValue={this.editRecord.expenseType} />                                                            
                                <label className="box-label">费用项目:</label>
                                <Checkbox.Group  options={this.subExpenseList} onChange={this.onChange111.bind(this, 'subExpense')} defaultValue={[this.editRecord.subExpense]} />                                                            
                            </div>
                        </div>      
                        <div className="row">
                            <div className="box">
                                <label className="box-label">应付款:</label>
                                <Input className="box-input" value={this.editRecord.cost} onChange={this.changeInput.bind(this, 'cost')} />
                                <label className="box-label">单价(应付):</label>
                                <Input className="box-input" onChange={this.changeUnitPriceForCost.bind(this, 'unitPriceCost')}  disabled={!this.state.unitPriceVisible}/>  
                                箱数: <p>  {this.containerCount}  </p>                              
                            </div>
                        </div> 

                        <div className="row">
                            <div className="box">
                                <label className="box-label">供应商:</label> 
                                <Select className="box-input"  
                                showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={this.vendorList} onChange={this.changeSelect.bind(this, 'vendorId')} defaultValue={this.editRecord.vendorId}/>       
                                <label className="box-label">备注:</label>
                                <Input className="box-input" defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')} /> 
                            </div>
                        </div>

                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Button type="primary" className="header-right" onClick={this.onExport}>导出Excel</Button>
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>                        
                        <Table  title={() => '合计金额: ' + this.totalCost} columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}
