import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

export default class ProductCode extends React.Component {
    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                        <Popconfirm
                            title="删除商品编码"
                            description="是否确定删除?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.onDelete(record)}
                        >
                            <DeleteOutlined title='删除' className="actionIcon"/>
                        </Popconfirm>
                        
                    </div>
                )}
        },
    {
        title: '商品编号',
        dataIndex: 'productNumber',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.id > b.id? 1 : -1,
    },
    {
        title: '品名',
        dataIndex: 'productName',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.productName > b.productName? 1 : -1,
    },
    {
        title: '规格',
        dataIndex: 'productSpec',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.productSpec > b.productSpec? 1 : -1,
    },
    {
        title: '单价',
        dataIndex: 'unitPriceList',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.unitPriceList > b.unitPriceList? 1 : -1,
    },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allUsers: any[] = [];
    
    editRecord = this.createProductCode();
    originalEditRecords: any = {};
    canBeSaved = false;
    
    createProductCode() {
        return { 
            id : 0,
            productNumber : "",
            productName : "",
            productSpec: "",
            unitPriceList: "",
        };
    }
    
    copyProductCode(productCode: any) {
        return { 
            id : productCode.id,
            productNumber : productCode.productNumber,
            productName : productCode.productName,
            productSpec: productCode.productSpec,
            unitPriceList: productCode.unitPriceList,
        };
    }

    onSearch = (value: any) => {
        this.setState({searchText: value.target.value})
        this.search(value.target.value);
    }
    search = (searchText: string) => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => ( item.productNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.productSpec.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.productName.toLowerCase().includes(searchText.toLowerCase()))
            )
          })
    }
    onEdit = (record: any) => {
        this.originalEditRecords = this.copyProductCode(record);
        this.editRecord = this.copyProductCode(record);
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    onDelete = (record: any) => {
        const ProductCode = {id: record.id, productName: record.productName};
        axiosInstance.post('/logistics/deleteProductCode', ProductCode).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createProductCode();
        this.originalEditRecords = this.createProductCode();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }
    save = () => {
        const productCode = this.copyProductCode(this.editRecord);        
        if (productCode.id === 0) {
            axiosInstance.post('/logistics/insertProductCode', productCode).then(
                response => {
                    this.init();
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateProductCode', productCode).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        this.setState({isEdit: false});
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }
    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.productNumber !== null && this.editRecord.productNumber !== '' &&
         this.editRecord.productName !== null && this.editRecord.productName !== '') {
            if (this.editRecord.productNumber !== this.originalEditRecords.productNumber ||
                this.editRecord.productName !== this.originalEditRecords.productName ||
                this.editRecord.productSpec !== this.originalEditRecords.productSpec ||
                this.editRecord.unitPriceList !== this.originalEditRecords.unitPriceList) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllProductCodes();
    }
    getAllProductCodes() {
        axiosInstance.get('/logistics/getAllProductCodes').then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                }
                this.setState({dataSource: this.originalData, isLoaded: true});
                if (this.state.searchText !== '') {
                    this.search(this.state.searchText);
                }                
            },
            error => {
                displayErrorMessage(error, '获取商品编码失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                    <span> 商品编码管理</span>
                </div>
                <div className="seperate">
                </div>                
                {this.state.isEdit? 
                    <div className="content">
                        <div className="row">
                            <div className="box">
                                <label className="box-label">商品编号:</label>
                                <Input className="box-input" defaultValue={this.editRecord.productNumber} onChange={this.changeInput.bind(this, 'productNumber')}/>   
                                <label className="box-label">品名:</label>
                                <Input className="box-input" defaultValue={this.editRecord.productName} onChange={this.changeInput.bind(this, 'productName')}/>   
                         
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">   
                                <label className="box-label">规格:</label>
                                <Input className="box-input" defaultValue={this.editRecord.productSpec} onChange={this.changeInput.bind(this, 'productSpec')}/>  
                                <label className="box-label">单价(多价格逗号分隔):</label>
                                <Input className="box-input" defaultValue={this.editRecord.unitPriceList} onChange={this.changeInput.bind(this, 'unitPriceList')}/>                      
                            </div>
                        </div>

                        <br/>
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                            <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                        </div>
                    </div>
                :
                    <div className="content">
                        <Input placeholder="输入要查询的字符" onChange={this.onSearch} className="tableSearch" value={this.state.searchText} /> 
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>
                        
                        <Table columns={this.columns} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                    </div>
                }
            </div>
        )      
    }
}
