import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker, Tabs, Card } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons';
import './Content.scss';
import Error from '../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';
import dayjs from 'dayjs';
import TabPane from 'antd/es/tabs/TabPane';
import BracketOrderPayment from './BracketOrder/BracketOrderPayment';
import BracketOrderBracketInventory from './BracketOrder/BracketOrderBracketInventory';
import BracketOrderProduct from './BracketOrder/BracketOrderProduct';
import BracketOrderInventory from './BracketOrder/BracketOrderInventory';

const dateFormat = 'YYYY-MM-DD';

export default class BracketOrder extends React.Component {    
    columns = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>
                        <EditOutlined className="actionIcon" title='修改' onClick={() => this.onEdit(record)}/>&nbsp; &nbsp;
                    </div>
                )}
        },
    {
        title: '订单编号',
        dataIndex: 'orderId',
        width: 100,
        showSorterTooltip: false,
    },          
    {
        title: '供应商',
        dataIndex: 'vendorName',
        width: 300,
        showSorterTooltip: false,
    },
   
    {
        title: '下单日期',
        dataIndex: 'orderDate',
        width: 150,      
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.orderDate > b.orderDate? 1 : -1,
    },

    {
        title: '交货日期',
        dataIndex: 'planedShipmentDate',
        width: 150,      
        showSorterTooltip: false,
    },
    {
        title: '总数量',
        dataIndex: 'totalqty',
        width: 120,    
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.totalqty > b.totalqty? 1 : -1,
    },
    {
        title: '总合计金额',
        dataIndex: 'totalPrice', 
        width: 120,    
        showSorterTooltip: false,
    },
    // {
    //     title: '支架型号',
    //     dataIndex: 'bracketType',
    //     width: 100,      
    //     showSorterTooltip: false,      
    // },    
    // {
    //     title: '数量',
    //     dataIndex: 'qty',
    //     width: 100,      
    //     showSorterTooltip: false,      
    // },    
    {
        title: '发往地',
        width: 100,      
        dataIndex: 'orderCity',
        showSorterTooltip: false,      
    },   
    {
        title: '订单状态',
        width: 100,  
        dataIndex: 'status',
        showSorterTooltip: false,      
        render: (_: any, record: any)=> {
            if (record.status == 'New') {
                return (
                    <div>
                        待对账
                    </div>
                )
            }else if (record.status == 'Checked') {
                return (
                    <div>
                        已对账
                    </div>
                )
            }else if (record.status == 'Invoiced') {
                return (
                    <div>
                        已收票
                    </div>
                )
            }else{
                return (
                    <div>
                        已付款
                    </div>
                )
            }
        }
    },   
    {
        title: '发票号',
        width: 100,  
        dataIndex: 'invoice',
        showSorterTooltip: false,
    },     
    {
        title: '备注',
        dataIndex: 'comments',
        showSorterTooltip: false,
    },
    
    // {
    //     title: '是否入库',
    //     dataIndex: 'status',
    //     showSorterTooltip: false,      
    //     render: (_: any, record: any)=> {
    //         if (record.allInInventory) {
    //             return (
    //                 <div>
    //                     已入库
    //                 </div>
    //             )
    //         }else {
    //             return (
    //                 <div>
    //                     未入库
    //                 </div>
    //             )
    //         }
    //     }
    // },  
    ];
    
    
    expandColumns = [
        {
            title: '商品编码',
            dataIndex: 'productNumber',
            width: 150,
            showSorterTooltip: false,
        },
        // {
        //     title: '品名',
        //     dataIndex: 'productName',
        //     showSorterTooltip: false,
        // },
        {
            title: '规格',
            width: 100,
            dataIndex: 'productSpec',
            showSorterTooltip: false,
        },
        {
            title: '编码范围',
            width: 300,
            dataIndex: 'bracketNumber',
            showSorterTooltip: false,
        },
        // {
        //     title: '支架类型',
        //     dataIndex: 'bracketType',
        //     showSorterTooltip: false,
        // },
        {
            title: '颜色',
            width: 150,   
            dataIndex: 'color',
            showSorterTooltip: false,
        },      
        {
            title: '单价',
            width: 150,    
            dataIndex: 'unitPrice',
            showSorterTooltip: false,
        },
        {
            title: '数量',
            width: 120,
            dataIndex: 'qty',
            showSorterTooltip: false,
        },
        {
            title: '合计金额',
            
            dataIndex: 'amount',
            showSorterTooltip: false,
        },
        // {
        //     title: '备注',
        //     dataIndex: 'comments',
        //     showSorterTooltip: false,
        // },
    ];
    originalData: any[] = [];
    state = {
        dataSource: this.originalData,
        isLoaded: false,
        isEdit: false,
        searchText: '',  
        expandDataSource: this.originalData,      
        isChangeStatus:false,
        expandedRowKeys: []
    };
    orderCityList: any[] = [];
    vendorList: any[] = [];
    recyclableList: any[] = [{label:'循环', value: 'recyclable'},{label:'一次性', value: 'onetime'}]; 
    statusList: any[] = [{label:'待对账', value: 'New'},{label:'已对账', value: 'Checked'},{label:'已收票', value: 'Invoiced'},{label:'已付款', value: 'Paid'}]; 
    inventoryList: any[] = [{label:'已入库', value: true},{label:'未入库', value: false}]; 
    transportTypeList: any[] = [{label:'自提', value: '自提'},{label:'配送', value: '配送'}]; 
    editRecord = this.createBracketOrder();
    originalEditRecords: any = {};
    canBeSaved = false;
    isNewSaved = false;
    productCodeList: any[] = [];
    
    createBracketOrder() {
        return { 
            orderId : 0,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            orderDate : "",
            dayJsOrderDate : dayjs(dayjs(new Date()), dateFormat),
            vendorId: 0,
            recyclable: '',
            vendorName : "",
            planedShipmentDate: "",
            dayJsPlanedShipmentDate : dayjs(dayjs(new Date()), dateFormat),
            bracketNumber: "",
            qty: 0,
            unitPrice: 0,
            allInInventory: false,
            allPaid: false,
            orderCity: "",            
            transportType:"",
            bracketType:"",
            usageChennel:"",
            batchNumber:"",
            colour:"",
            inputValueAddedTax:"",
            comments : "",
            invoice : "",            
            status : "New",
            project : "",
            consignee : "",
            paid: 0,
        };
    }
    
    copyBracketOrder(bracketOrder: any) {
        return { 
            orderId : bracketOrder.orderId,
            createdBy: sessionStorage.getItem("loginName")?.replaceAll('"', ''),
            orderDate : bracketOrder.orderDate,
            dayJsOrderDate :  bracketOrder.orderDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(bracketOrder.orderDate, dateFormat),
            vendorId: bracketOrder.vendorId,
            recyclable: bracketOrder.recyclable,
            vendorName : bracketOrder.vendorName,
            planedShipmentDate: bracketOrder.planedShipmentDate,
            dayJsPlanedShipmentDate :  bracketOrder.planedShipmentDate === null? dayjs(dayjs(new Date()), dateFormat) : dayjs(bracketOrder.planedShipmentDate, dateFormat),
            bracketNumber: bracketOrder.bracketNumber,
            qty: bracketOrder.qty,
            unitPrice: bracketOrder.unitPrice,
            allInInventory: bracketOrder.allInInventory,
            allPaid: bracketOrder.allPaid,
            orderCity: bracketOrder.orderCity,
            transportType:bracketOrder.transportType,
            bracketType:bracketOrder.bracketType,
            usageChennel:bracketOrder.usageChennel,
            batchNumber:bracketOrder.batchNumber,
            colour:bracketOrder.colour,            
            inputValueAddedTax:bracketOrder.inputValueAddedTax,
            comments : bracketOrder.comments,
            invoice : bracketOrder.invoice,             
            status : bracketOrder.status,
            project : bracketOrder.project,
            consignee : bracketOrder.consignee,
            paid : bracketOrder.paid,
        };
    }

    searchText = {
        recyclable: '',
        status: '',
        vendorName:'',    
        orderCity:'',  
        invoice:'',  
        orderId:'', 
    }

    changeSelectSearch = (field: string, event: any) => {
        (this.searchText as any)[field] = event
        this.search();
    }
    search = () => {
        this.setState({
            dataSource: this.originalData.filter((item: any) => {             
                if (this.searchText.orderId != ''  && !String(item.orderId).includes(this.searchText.orderId)) {
                    return false;
                }  
                if (this.searchText.status != ''  && item.status !== this.searchText.status) {
                    return false;
                }
                if (this.searchText.vendorName != ''  && !item.vendorName?.toLowerCase().includes(this.searchText.vendorName?.toLowerCase())) {
                    return false;
                } 
                if (this.searchText.orderCity != ''  && !item.orderCity?.toLowerCase().includes(this.searchText.orderCity?.toLowerCase())) {
                    return false;
                }     
                if (this.searchText.invoice != ''  && !item.invoice?.toLowerCase().includes(this.searchText.invoice?.toLowerCase())) {
                    return false;
                }         
             
                return true;
            }
            )
          })
    }


    onEdit = (record: any) => {
        this.originalEditRecords = this.copyBracketOrder(record);
        this.editRecord = this.copyBracketOrder(record);
       // this.getBracketOrderPaymentByOrderId();
        this.canBeSaved = false;
        this.setState({isEdit: true});
    }

    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;
        this.checkCanBeSaved();
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    deleteSelected = () => {       
        if(this.selectedRows.length==0) {
            alert('请选择要删除的条目!');
            return;
        }
        this.selectedRows.forEach(element => {
            const BracketOrder = {orderId: element.orderId, orderDate: element.orderDate};
            axiosInstance.post('/logistics/deleteBracketOrder', BracketOrder).then(
                response => {
                    this.init();                   
                },
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        })               
        message.success('删除成功');
    }


    onDelete = (record: any) => {
        const BracketOrder = {orderId: record.orderId, orderDate: record.orderDate};
        axiosInstance.post('/logistics/deleteBracketOrder', BracketOrder).then(
            response => {
                this.init();
                message.success('删除成功');
            },
            error => {
                displayErrorMessage(error, '删除失败');
            }
        )
    }
    onAdd = () => {
        this.editRecord = this.createBracketOrder();
        this.originalEditRecords = this.createBracketOrder();
        this.canBeSaved = false;
        this.setState({isEdit: true, paid: 0});
    }

    onExport = () => {
        this.setState({isLoaded: false});
        const ids = this.selectedRows.map(element => element.orderId).join(',');        
        axiosInstance.get('/logistics/exportBracketOrder', {params: {orderIds: ids},responseType: 'blob'}).then(               
            response => {
                this.setState({isLoaded: true});
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const fileName = '支架订单明细.xlsx';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                message.success('导出成功!');
            },
            error => {
                displayErrorMessage(error, '导出失败!');
            }
        );

    }
   
    save = () => {
        const bracketOrder = this.copyBracketOrder(this.editRecord);
        if (bracketOrder.orderId === 0) {
            axiosInstance.post('/logistics/insertBracketOrder', bracketOrder).then(
                response => {
                    this.editRecord.orderId = response.data[0].orderId;
                    this.originalEditRecords = {...this.editRecord};
                    this.canBeSaved = false;
                    this.isNewSaved = true;
                    this.setState({isEdit: true});
                    message.success('新增成功');
                },
                error => {
                    displayErrorMessage(error, '新增失败');
                }
            )

        }else {
            axiosInstance.post('/logistics/updateBracketOrder', bracketOrder).then(
                response => {
                    this.init();
                    message.success('修改成功');
                },
                error => {
                    displayErrorMessage(error, '修改失败');
                }
            )

        }
    }
    cancel = () => {
        if (this.isNewSaved) {
            this.init();
        }else {
            this.setState({isEdit: false});
        }
    }
    changeInput = (field: string, event: any) => {
        (this.editRecord as any)[field] = event.target.value;
        this.checkCanBeSaved();
    }    
    changeSelect = (field: string, event: any) => {
        (this.editRecord as any)[field] = event
        this.checkCanBeSaved();
    }
    changeOrderDate = (date: any, dateString: string) => {
        this.editRecord.orderDate = dateString;
        this.checkCanBeSaved();
    }
    changePlanedShipmentDate = (date: any, dateString: string) => {
        this.editRecord.planedShipmentDate = dateString;
        this.checkCanBeSaved();
    }

    checkCanBeSaved() {
        this.canBeSaved = false;
        if (this.editRecord.orderDate !== null && this.editRecord.orderDate !== '' &&
            this.editRecord.vendorId !== null && this.editRecord.vendorId !== 0 &&            
            this.editRecord.planedShipmentDate !== null && this.editRecord.planedShipmentDate !== '' 
            // this.editRecord.recyclable !== null && this.editRecord.recyclable !== '' &&
            // this.editRecord.qty !== null && this.editRecord.qty !== 0 &&
            // this.editRecord.unitPrice !== null && this.editRecord.unitPrice !== 0
           // this.editRecord.orderCity !== null && this.editRecord.orderCity !== '' &&
           // this.editRecord.transportType !== null && this.editRecord.transportType !== ''  &&  
           // this.editRecord.status !== null && this.editRecord.status !== ''           
        ) {
            if (this.editRecord.orderDate !== this.originalEditRecords.orderDate ||
                this.editRecord.vendorId !== this.originalEditRecords.vendorId ||
                // this.editRecord.recyclable !== this.originalEditRecords.recyclable ||   
                this.editRecord.planedShipmentDate !== this.originalEditRecords.planedShipmentDate ||
                this.editRecord.bracketNumber !== this.originalEditRecords.bracketNumber ||
                // this.editRecord.qty !== this.originalEditRecords.qty ||
                // this.editRecord.unitPrice !== this.originalEditRecords.unitPrice ||
                // this.editRecord.orderCity !== this.originalEditRecords.orderCity ||
                this.editRecord.transportType !== this.originalEditRecords.transportType ||
                // this.editRecord.bracketType !== this.originalEditRecords.bracketType ||
                // this.editRecord.usageChennel !== this.originalEditRecords.usageChennel ||
                // this.editRecord.batchNumber !== this.originalEditRecords.batchNumber ||
                // this.editRecord.colour !== this.originalEditRecords.colour ||                
                this.editRecord.project !== this.originalEditRecords.project ||        
                this.editRecord.consignee !== this.originalEditRecords.consignee ||        
                this.editRecord.comments !== this.originalEditRecords.comments || 
                this.editRecord.invoice !== this.originalEditRecords.invoice || 
                this.editRecord.status !== this.originalEditRecords.status ) {
                    this.canBeSaved = true;
            }
        }
        this.setState({});
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    componentWillReceiveProps(nextProps: Readonly<{}>, nextContext: any): void {
        this.init();
    }

    init() {
        this.isNewSaved = false;
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getAllBracketOrder();
        this.getAllVendors();
        this.getAllCity();
        this.getAllProductCodes();
    }
    getAllBracketOrder() {  
        axiosInstance.get('/logistics/getAllBracketOrder').then(
            response => {
                this.originalData = response.data;                                
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].orderId;                    
                }
                this.setState({dataSource: this.originalData, isLoaded: true}); 
            },
            error => {
                displayErrorMessage(error, '获取支架订单失败');
            }
        )
    }
    getAllVendors() {
        axiosInstance.get('/logistics/getAllVendors').then(
            response => {
                this.vendorList= [];
                for(let i = 0; i < response.data.length; i++) {
                    this.vendorList.push({label:response.data[i].vendorName, value: response.data[i].vendorId});               
                }
            },
            error => {
                displayErrorMessage(error, '获取供应商失败');
            }
        )
    }
    getAllProductCodes() {
        axiosInstance.get('/logistics/getAllProductCodes').then(
            response => {
                // this.originalData = response.data;
                for (let i = 0; i < response.data.length; i++) { 
                    let item = response.data[i].productNumber + "-" 
                        + response.data[i].productName + "-" 
                        + response.data[i].productSpec;
                    let itemValue = response.data[i].id + "*" 
                        + response.data[i].productName + "*" 
                        + response.data[i].productSpec + "*"
                        + response.data[i].unitPriceList;            
                    this.productCodeList.push({label:item, value: itemValue});
                }         
            },
            error => {
                displayErrorMessage(error, '获取商品编码失败');
            }
        )

    }
    onSearchInvoice = (value: any) => {
        this.searchText.invoice = value.target.value;
        this.search();
    }

    onSearchOrderCity = (value: any) => {
        this.searchText.orderCity = value.target.value;
        this.search();
    }

    onSearchOrderId = (value: any) => {
        this.searchText.orderId = value.target.value;
        this.search();
    }

    onSearchVendorName = (value: any) => {
        this.searchText.vendorName = value.target.value;
        this.search();
    }
    getAllCity() {
        axiosInstance.get('/logistics/getAllCommonProperties').then(
            response => {
                this.orderCityList = [];           
                for(let i = 0; i < response.data.length; i++) {
                    if (response.data[i].propertyKey == 'orderCity') {
                        this.orderCityList.push({label:response.data[i].propertyValue, value: response.data[i].propertyValue});
                    }
                }
            },
            error => {
                displayErrorMessage(error, 'Get common properties failed!');
            }
        )       
    }
    expandedRowRender = () => (
        <Table
          columns={this.expandColumns}
          dataSource={this.state.expandDataSource}
          pagination={false}
        />
    );
      
    changeStatus = () => { 
        this.setState({isChangeStatus: !this.state.isChangeStatus});        
    };

    onStatusChange = () => {        
        this.selectedRows.forEach(element => {            
            const order = {orderId: element.orderId,status:this.editRecord.status};
            axiosInstance.post('/logistics/updateBracketOrderStatus', order).then(
                response => {
                    this.init();                   
                },
                error => {
                    displayErrorMessage(error, '操作失败');
                }
            )
        })          
        message.success('操作成功');        
        this.setState({isChangeStatus: !this.state.isChangeStatus});         
    }

    changeStatusValue = (event: any) => {
        this.editRecord.status = event;        
    }

    getAllBracketOrderProducts(record: any) {
        axiosInstance.get('/logistics/getAllBracketOrderProducts',{params: {orderId: record.orderId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].id;
                }
                this.setState({expandDataSource: this.originalData});
            },
            error => {
                displayErrorMessage(error, '获取商品失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="title">
                     <span> 支架采购订单</span> 
                </div>
                <div className="seperate">
                </div>
                
                
                {this.state.isEdit? 
                    <div className="content">
                        <div>
                            <Button className="header-right" type="primary" onClick={this.cancel}>退出</Button>
                        </div>
                        <Tabs defaultActiveKey="1">                      
                            <TabPane tab='基本信息' key = '1'>
                            <Card size="small" style={{ width: '90%' }}>    
                                <div className="row">
                                    <div className="box">
                                    <label className="box-label" style={{ width: '100px' }}>订单编号:</label>
                                        <Input className="box-input" style={{ width: '200px' }} disabled defaultValue={this.editRecord.orderId==0?'':this.editRecord.orderId} />

                                    <label className="box-label"style={{ width: '100px' }}>供应商:</label>
                                        <Select className="box-input"  style={{ width: '200px' }}
                                            showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={this.vendorList} onChange={this.changeSelect.bind(this, 'vendorId')} defaultValue={this.editRecord.vendorName}/>  


                                    <label className="box-label" style={{ width: '100px' }}>订购日期:</label>
                                        {(this.editRecord.orderDate + "") == "" ?
                                            <DatePicker style={{ width: '200px' }} className="box-input" onChange={this.changeOrderDate} />
                                            :
                                            <DatePicker style={{ width: '200px' }} className="box-input" onChange={this.changeOrderDate} defaultValue={this.editRecord.dayJsOrderDate} />}

                                    <label className="box-label" style={{ width: '100px' }}>交货日期:</label>
                                        {(this.editRecord.planedShipmentDate + "") == "" ?
                                            <DatePicker style={{ width: '200px' }} className="box-input" onChange={this.changePlanedShipmentDate} />
                                            :
                                            <DatePicker style={{ width: '200px' }} className="box-input" onChange={this.changePlanedShipmentDate} defaultValue={this.editRecord.dayJsPlanedShipmentDate} />}             
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="box">
                                        <label className="box-label" style={{ width: '100px' }}>项目:</label>
                                        <Input className="box-input" style={{ width: '200px' }} defaultValue={this.editRecord.project} onChange={this.changeInput.bind(this, 'project')} />

                                        <label className="box-label" style={{ width: '100px' }}>收货人:</label>
                                        <Input className="box-input" style={{ width: '200px' }}  defaultValue={this.editRecord.consignee} onChange={this.changeInput.bind(this, 'consignee')}/>

                                        <label className="box-label" style={{ width: '100px' }}>收货地址:</label>
                                        <Input className="box-input" style={{ width: '200px' }}  defaultValue={this.editRecord.orderCity} onChange={this.changeInput.bind(this, 'orderCity')}/>                                     

                                        <label className="box-label" style={{ width: '100px' }}>运输类型:</label>                                            
                                                <Select className="box-input"  style={{ width: '200px' }} options={this.transportTypeList} onChange={this.changeSelect.bind(this, 'transportType')} defaultValue={this.editRecord.transportType} />                                        
                                    </div>
                                </div>
                            
                                <div className="row">
                                <div className="box">
                                        <label className="box-label" style={{ width: '100px' }} >订单状态:</label>
                                        <Select className="box-input"  style={{ width: '200px' }} options={this.statusList} onChange={this.changeSelect.bind(this, 'status')} defaultValue={this.editRecord.status} />                                                       
                                        <label className="box-label" style={{ width: '100px' }}>发票号:</label>                                            
                                        <Input className="box-input" style={{ width: '200px' }}  defaultValue={this.editRecord.invoice} onChange={this.changeInput.bind(this, 'invoice')}/>                                            
                                        <label className="box-label" style={{ width: '100px' }}>备注:</label>                                            
                                        <Input className="box-input" style={{ width: '200px' }}  defaultValue={this.editRecord.comments} onChange={this.changeInput.bind(this, 'comments')}/>                                            
                                    </div>
                                </div>

                            <br/>
                            {
                                this.editRecord.orderId == 0? 
                                '' :
                                <div>     
                                    <div className="row" >                                     
                                        <BracketOrderProduct  orderId={this.editRecord.orderId} productCodeList={this.productCodeList}/>
                                    </div>
                                </div>
                            }
                            <div>
                                <Button className="header-right" type="primary" onClick={this.cancel}>取消</Button>
                                <Button className="header-right" type="primary" onClick={this.save} disabled={!this.canBeSaved}>保存</Button>
                            </div>
                            </Card>
                            </TabPane>
                            <TabPane tab='入库信息' key = '2' disabled={this.editRecord.orderId == 0}>
                                 <BracketOrderInventory orderId={this.editRecord.orderId}/>
                            </TabPane>
                            <TabPane tab='付款记录' key = '3' disabled={this.editRecord.orderId == 0}>
                                <BracketOrderPayment orderId={this.editRecord.orderId} />
                            </TabPane>
                        </Tabs> 
                    </div>
                :
                    <div className="content">                    
                        <label className="box-label">订单号:</label>
                        <Input onChange={this.onSearchOrderId}  className="tableSearch" value={this.searchText.orderId} />       
                        <label className="box-label">订单状态:</label>
                        <Select style={{ width: '172px' }} options={[{label:'--全部--', value: ''},...this.statusList]} onChange={this.changeSelectSearch.bind(this, 'status')}/>          
                        <label className="box-label">供应商:</label>
                        <Input onChange={this.onSearchVendorName}  className="tableSearch" value={this.searchText.vendorName} />  
                        <label className="box-label">发往地:</label>
                        <Input onChange={this.onSearchOrderCity}  className="tableSearch" value={this.searchText.orderCity} />                          
                        <label className="box-label">发票号:</label>
                        <Input onChange={this.onSearchInvoice}  className="tableSearch" value={this.searchText.invoice} /> 
                        
                        <Button type="primary" className="header-right" onClick={this.onAdd}>新增</Button>    
                        <Button type="primary" className="header-right" disabled={this.selectedRows.length==0} onClick={this.onExport}>导出Excel</Button>
                        <div className="row">
                            <Button type="primary" className="header-left" onClick={this.deleteSelected} disabled={this.selectedRows.length==0}>批量删除</Button>  
                            <Button type="primary" className="header-left" onClick={this.changeStatus} disabled={this.selectedRows.length==0}>批量改状态</Button>  
                            {this.state.isChangeStatus? 
                            <div className="box">
                                <label className="box-label">状态:</label>
                                <Select className="box-input" style={{width: '150px'}} options={this.statusList} onChange={this.changeStatusValue}/> 
                                <Button type="primary" className="header-left" onClick={this.onStatusChange}>确定</Button>  
                            </div>
                            :''
                        }
                        </div>                     
                  
                        <Table columns={this.columns} rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }}  dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}
                            expandable={{ 
                                expandedRowRender: this.expandedRowRender, 
                                defaultExpandedRowKeys: [],           
                                expandedRowKeys: this.state.expandedRowKeys,                     
                                onExpand: (expanded:boolean, record: any) => {
                                    if (expanded) {
                                        this.setState({expandedRowKeys: [record.key]}); 
                                        this.getAllBracketOrderProducts(record);
                                    } else {
                                        this.setState({expandedRowKeys: []});
                                    }
                                },
                                }}
                                summary={() => {
                                    let allTotalqty = 0;
                                    let allTotalPrice = 0;
                                    if(this.selectedRows.length>0){
                                        this.selectedRows?.forEach(({ totalqty,totalPrice }) => {
                                            allTotalqty += totalqty;
                                            allTotalPrice += totalPrice;                                                                          
                                        });  
                                    }
                                    return (
                                    <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>选中汇总</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{allTotalqty % 1 === 0 ? allTotalqty : allTotalqty.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{allTotalPrice % 1 === 0 ? allTotalPrice : allTotalPrice.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>                                    
                                    <Table.Summary.Cell index={10} />
                                    </Table.Summary.Row>
                                    ); 
                                    }}/>
                    </div>
                }
            </div>
        )      
    }
}
