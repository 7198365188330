import React from 'react';
import ReactDOM from 'react-dom/client';
import {axiosInstance} from '../../config/axios-interceptor';
import { Table, Button, Input, message, Popconfirm, Select, SelectProps, DatePicker } from 'antd';
import { EditOutlined, DeleteOutlined, RetweetOutlined, PlusOutlined } from '@ant-design/icons';
import '../Content.scss';
import Error from '../../components/Error/Error';
import { displayErrorMessage } from '@/utils/utilFunction';

const dateFormat = 'YYYY-MM-DD';

export default class ShipmentConsumables extends React.Component {
    props = {planId: 0,stationId:0};
    constructor(props:any) {
        super(props);
      }
      
    columns1 = [
        {
            title: '操作',
            key: 'operation',
            width: 100,
            render: (_: any, record: any)=> {
                return (
                    <div>     
                        <PlusOutlined title='使用' onClick={() => this.insertShipmentConsumables(record)} className="actionIcon"/>
                     </div>
                )}
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            showSorterTooltip: false,
        },
        {
            title: '品名',
            dataIndex: 'productName',
            showSorterTooltip: false,
        },
        {
            title: '规格',
            dataIndex: 'productSpec',
            showSorterTooltip: false,
        },
        // {
        //     title: '耗材名称',
        //     dataIndex: 'consumablesName',
        //     showSorterTooltip: false,
        // },
        {
            title: '入库日期',
            dataIndex: 'entryDate',
            showSorterTooltip: false,
        },
        {
            title: '数量',
            dataIndex: 'consumablesAmount',
            showSorterTooltip: false,
        },
        {
            title: '本次使用数量',
            dataIndex: 'inQty',
            showSorterTooltip: false,
            render: (_: any, record: any)=> {
                return (
                    <div>                   
                        <Input style={{width: "100px"}} defaultValue={record.consumablesAmount} onChange={this.changeInQty.bind(this, record)}/>
                     </div>
                )}
        },
    ];

    changeInQty(record: any, event: any) {
        record.inQty = event.target.value;
    }
    
    columns2 = [
            {
                title: '操作',
                key: 'operation',
                width: 100,
                render: (_: any, record: any)=> {
                    return (
                        <div>
                            <Popconfirm
                                title="删除支架"
                                description="是否确定删除?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => this.onDelete(record)}
                            >
                                <DeleteOutlined title='删除' className="actionIcon"/>
                            </Popconfirm>
                            
                        </div>
                    )}
            },
        {
            title: '订单号',           
            dataIndex: 'orderId',
            showSorterTooltip: false,      
            render: (_: any, record: any)=> {
                if (record.orderId == '0') {
                    return (
                        <div>
                            无
                        </div>
                    )               
                }else{
                    return (
                        <div>
                            {record.orderId}
                        </div>
                    )
                }
            }
        },   
       
        {
            title: '品名',
            dataIndex: 'productName',
            showSorterTooltip: false,
        },
        {
            title: '规格',
            dataIndex: 'productSpec',
            showSorterTooltip: false,
        },
    // {
    //     title: '耗材名称',
    //     dataIndex: 'consumablesName',
    //     showSorterTooltip: false,
    //     sorter: (a: any, b: any) => a.consumablesName > b.consumablesName? 1 : -1,
    // },
    {
        title: '数量',
        dataIndex: 'amount',
        showSorterTooltip: false,
    },    
    ];
    originalData: any[] = [];
    originalInvData: any[] = [];
    state = {
        dataSource: this.originalData,
        inventoryList: this.originalInvData,
        isLoaded: false,
        isEdit: false,
        searchText: '',
    };
    allPermissions: any[] = [];
    
    originalEditRecords: any = {};
    isLoaded1 = false;
    isLoaded2 = false;
    canBeSaved = false;
    
    insertShipmentConsumables = (record: any) => {
        if (record.consumablesAmount < record.inQty) {
            alert("输入的数量太大了！");
            return;
        }
        axiosInstance.post('/logistics/insertShipmentConsumables', {planId: this.props.planId, consumablesId: record.consumablesId,amount: record.inQty,orderId: record.orderId, productId: record.productId}).then(
            response => {
                this.init();
                message.success('操作成功');
            },
            error => {
                displayErrorMessage(error, '操作失败');
            }
        )
    }
        onDelete = (record: any) => {
            const ShipmentConsumables = {id: record.id, planId: this.props.planId, consumablesId: record.consumablesId, amount: record.amount};
            axiosInstance.post('/logistics/deleteShipmentConsumablesById', ShipmentConsumables).then(
                response => {
                    this.init();
                    message.success('删除成功');
                },
                error => {
                    displayErrorMessage(error, '删除失败');
                }
            )
        }
    cancel = () => {
        this.setState({isEdit: false});
    }
    onSearch = (value: any) => {
        this.setState({searchText: value.target.value});
        this.search(value.target.value);
    }
    search = (searchText: string) => {    
        if (searchText == '') {
            this.setState({
                inventoryList: this.originalInvData
            });
        }else {
            this.setState({
                inventoryList: this.originalInvData.filter((item: any) => (item.orderId == searchText))
            });
        }
    }
    componentDidMount() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (roles.includes('admin')) {
            this.init();
        }
    }
    init() {
        this.isLoaded1 = false;
        this.isLoaded2 = false;
        this.setState({dataSource: null, isLoaded: false, isEdit: false});
        this.getConsumableInventoryByStationId();
        this.getShipmentConsumablesByPlanId();
    }
    getConsumableInventoryByStationId() {
        axiosInstance.get('/logistics/getConsumableInventoryByStationId', {params: {stationId: this.props.stationId}}).then(
            response => {
                this.originalInvData = response.data;
                for (let i = 0; i < this.originalInvData.length; i++) {
                    this.originalInvData[i].key = i;
                    this.originalInvData[i].inQty = this.originalInvData[i].consumablesAmount;
                }
                this.isLoaded1 = true;
                this.setState({inventoryList: this.originalInvData});
                if (this.isLoaded1 && this.isLoaded2) {
                    this.setState({isLoaded: true});
                }
            },
            error => {
                displayErrorMessage(error, '获取库存耗材信息失败');
            }
        )

    }

    
    selectedRows: any[] = [];
    onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {        
        this.selectedRows = selectedRows;        
    };

    rowSelection = {
        onChange: this.onSelectChange,
    };

    handleSelected = () => {           
        if(this.selectedRows.length==0) {
            alert('请选择要入库的条目!');
            return;
        }
        this.selectedRows.forEach(element => {
            this.insertShipmentConsumables(element);            
        })                         
    }
 
    
    getShipmentConsumablesByPlanId() {
        axiosInstance.get('/logistics/getShipmentConsumablesByPlanId', {params: {planId: this.props.planId}}).then(
            response => {
                this.originalData = response.data;
                for (let i = 0; i < this.originalData.length; i++) {
                    this.originalData[i].key = this.originalData[i].consumablesId;
                }
                this.setState({dataSource: this.originalData});
                this.isLoaded2 = true;
                if (this.isLoaded1 && this.isLoaded2) {
                    this.setState({isLoaded: true});
                }
            },
            error => {
                displayErrorMessage(error, '获取耗材失败');
            }
        )

    }
    render() {
        const roles = sessionStorage.getItem("permissions") || '';
        if (!roles.includes('admin')) {
            return <Error />
        }
        return (
            <div>
                <div className="seperate">
                </div>
                
                <div className="content">
                    <Input className="tableSearch" value={this.state.searchText} /> 
                    <Table  rowSelection={{
                            type: 'checkbox',
                            ...this.rowSelection,
                            }} columns={this.columns1} dataSource={this.state.inventoryList} bordered loading={!this.state.isLoaded}/>  
                    <Button type="primary" className="header-right" onClick={this.handleSelected} >批量绑定</Button>  
                    <br/><br/>
                    已使用的耗材:                   
                    <Table columns={this.columns2} dataSource={this.state.dataSource} bordered loading={!this.state.isLoaded}/>
                </div>
            </div>
        )      
    }
}
